import { render, staticRenderFns } from "./comp_ProductReviews.vue?vue&type=template&id=726ca725&"
import script from "./comp_ProductReviews.vue?vue&type=script&lang=js&"
export * from "./comp_ProductReviews.vue?vue&type=script&lang=js&"
import style0 from "./comp_ProductReviews.vue?vue&type=style&index=0&id=726ca725&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRating } from 'vuetify/lib/components/VRating';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VIcon,VProgressLinear,VRating,VSkeletonLoader,VSpacer})
