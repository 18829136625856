<script>
import appbarMixin from '@/v-shop/appbarMixin'

export default {
	cssVars: {
		selector: '.appbar',
	},
	mixins: [appbarMixin],
	hooksKey: 'AppBar-Top',
	props: {
		iconColor: [Boolean, String],
		cartIconColor: String,
	},
	provide() {
		return {
			hookContainer: () => this,
		}
	},
	computed: {
		mobileSearchClass() {
			return 'base'
		},
		containerStyle() {
			return ''
		},
	},
}
</script>

<template>
	<div class="appbar base w100">
		<v-container class="d-flex align-center justify-space-between py-3" :style="containerStyle">
			<v-btn aria-label="menu" @click="menuMobileDrawer = true" icon v-if="$b.m" class="ml-3">
				<v-icon :color="iconColor || $vars.basetext" size="30">mdi-menu</v-icon>
			</v-btn>

			<CategoriesMegaMenu
				v-if="
					!$b.m &&
					showMegaMenu &&
					megaMenuType === 'vertical' &&
					megaMenuLocation === 'top' &&
					config.roundAppBarMenuLocation === 'before'
				"
				closeOnScroll
				:btnText="config.megaMenuBtnText"
				:dense="$b.t"
				:denseWidthScale="0.2"
				:widthScale="0.2"
				submenuStyles="width: 995px"
				:iconPosition="iconPosition"
			/>

			<SafeLink to="/" v-if="!showMobileSearch">
				<Media :src="$b({ d: logo, mt: logoMobile })" />
			</SafeLink>

			<CategoriesMegaMenu
				v-if="
					!$b.m &&
					showMegaMenu &&
					megaMenuType === 'vertical' &&
					megaMenuLocation === 'top' &&
					config.roundAppBarMenuLocation === 'after'
				"
				closeOnScroll
				:btnText="config.megaMenuBtnText"
				:dense="$b.t"
				:denseWidthScale="0.2"
				:widthScale="0.2"
				submenuStyles="width: 850px"
				:iconPosition="iconPosition"
			/>

			<AutocompleteSearchField
				closeOnScroll
				v-if="$b.td"
				class="mx-3 w80 fixedHeight"
				:iconContainerSize="{ width: '40px', height: '40px' }"
			/>

			<AppBar-Buttons :cartIconColor="cartIconColor" />
		</v-container>

		<AppBarMobileSearch :class="mobileSearchClass" :transparent="makeAppbarTransparent" />
	</div>
</template>

<style lang="scss" scoped>
.btn,
::v-deep .btn {
	color: var(--basetext) !important;
}

::v-deep span .v-badge__badge {
	background-color: var(--cta) !important;
	color: var(--ctatext) !important;
}

.overflow {
	overflow-x: scroll;
}
.w80 {
	width: 40%;
	min-width: 13rem;
}

.fixedHeight {
	height: 40px;
	vertical-align: middle;
}
.search-mobile {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100px;
	z-index: 10;
	transform: translateY(-100%);
}
.search-animation-entry {
	animation: searchMobileEntry 0.3s forwards;
}
.search-animation-leave {
	animation: searchMobileLeave 0.3s forwards;
}
@keyframes searchMobileEntry {
	from {
		transform: translateY(-100%);
	}
	to {
		transform: translateY(0%);
	}
}
@keyframes searchMobileLeave {
	from {
		transform: translateY(0%);
	}
	to {
		transform: translateY(-100%);
	}
}
</style>
