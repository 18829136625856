import Vue from 'vue'
const extensions = {
	video: ['mp4', 'cmv', 'mov'],
	image: ['jpg', 'jpeg', 'png'],
}

let getType = (mediaSrc) => {
	if (typeof mediaSrc === 'string') {
		const ext = mediaSrc.split('.').pop()
		if (extensions.video.includes(ext)) {
			return 'video'
		} else if (mediaSrc.includes('youtube')) {
			return 'youtube-video'
		} else {
			return 'image'
		}
	} else {
		return mediaSrc?.type || 'image'
	}
}

let getSrc = (mediaSrc, type, srv) => {
	if (typeof mediaSrc == 'string') return mediaSrc

	let url
	if (mediaSrc?.fullUrl) {
		url = mediaSrc.fullUrl
	} else if (mediaSrc?.url) {
		url = `${srv('cdnBaseUrl')}/${mediaSrc.url}`
	} else {
		return null
	}

	if (type === 'image' && srv('acceptWebp') && !url.endsWith('.webp')) {
		url += '.webp'
	}
	return url
}

let getMediaItem = (mediaSrc, srv) => {
	let type = getType(mediaSrc)
	let src = getSrc(mediaSrc, type, srv)
	let mediaData = typeof mediaSrc == 'string' ? {} : { ...mediaSrc }
	return { ...mediaData, src, type }
}

export default (options) => {
	options.media = (mediaSrc) => getMediaItem(mediaSrc, options.srv)
}

Vue.mixin({
	beforeCreate() {
		this.$media = this.$root?.$options.media
	},
})

