import createApp from '@/utils/create-app'
import AppComponent from './app/App.vue'
import { routesProviders, storesProviders, pluginsRunners, getComponents } from '@/v-shop/autoloader'
const autoloaderComponents = getComponents()

export default function ({ ssrContext, window, errorHandler }) {
	return createApp({
		ssrContext,
		window,
		AppComponent,
		routesProviders,
		storesProviders,
		pluginsRunners,
		autoloaderComponents,
		errorHandler,
	})
}
