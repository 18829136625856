import createApp from './app'
// import axios from 'axios'

/*const errorHandler = (err, vm, info, options) => {
	axios.post(options.apis.shopApi.makeUrl('/errlog'), { err: String(err.stack), info })
}*/

const app = createApp({ window })

app.$options.router.onReady(() => {
	app.$mount('#app', true)
})
