<script>
import LazyHydrate from 'vue-lazy-hydration'

export default {
	components: {
		LazyHydrate,
	},
	hooksKey: 'ShopApp',
	provide() {
		return {
			hookContainer: () => this,
		}
	},
	data() {
		return {
			renderLoginDrawer: false,
			renderCartDrawer: false,
			renderMobileMenuDrawer: false,
			renderOfferDrawer: false,
			renderRoundAppBarMenuDrawer: false,
			transitioning: false,
			renderWhatsappChat: false,
			renderBrandsDialog: false,
		}
	},
	computed: {
		purchaseMode() {
			return this.$srv('purchaseMode')
		},
		showOffset() {
			const config = this.$srv('AppBarConfig')
			if (!config) return false
			return (
				this.$b.d &&
				!config.transparent &&
				(config.type === 'regular' || (config.type === 'transparent' && this.$route.name !== 'home'))
			)
		},
	},
	methods: {
		watchOnce(storeKey, cb) {
			let unwatch = this.$watch(
				() => this.$store.get(storeKey),
				(value) => {
					if (value) {
						cb()
						unwatch()
					}
				}
			)
		},
	},
	mounted() {
		// this code makes drawers to be rendered on client side only
		// and only when they are first time opened
		this.watchOnce('shop/cartDrawer', () => (this.renderCartDrawer = true))
		this.watchOnce('shop/brandsDialog', () => (this.renderBrandsDialog = true))
		// this.watchOnce('shop/loginDrawer', () => (this.renderLoginDrawer = true))
		this.renderLoginDrawer = true
		this.watchOnce('shop/offersDrawer', () => (this.renderOfferDrawer = true))
		this.watchOnce('shop/showWhatsappChat', () => (this.renderWhatsappChat = true))

		if (this.$b.m) {
			this.watchOnce('shop/menuMobileDrawer', () => (this.renderMobileMenuDrawer = true))
		}

		if (this.$srv('AppBarConfig.type', 'regular') != 'regular' && this.$b.d) {
			this.watchOnce('shop/appBarRoundDrawer', () => (this.renderRoundAppBarMenuDrawer = true))
		}
	},
}
</script>

<template>
	<div>
		<LazyHydrate when-idle>
			<AppBar />
		</LazyHydrate>
		<div v-if="showOffset" :style="`height: ${$srv('AppBarConfig.offset')}px`" class="base"></div>
		<AppBarRound-Drawer v-if="renderRoundAppBarMenuDrawer" />
		<MobileMenuDrawer v-if="renderMobileMenuDrawer" />
		<LoginDrawer v-if="renderLoginDrawer" />
		<CartDrawer v-if="purchaseMode != 'single_product' && renderCartDrawer" />
		<OfferDrawerButton />
		<OfferDrawer v-if="renderOfferDrawer" />
		<WhatsappBtn />
		<WhatsappChat v-if="renderWhatsappChat" />
		<v-main :class="{ transitioning }">
			<transition
				name="viewanim"
				:duration="{ enter: 500, leave: 0 }"
				@enter="transitioning = true"
				@after-enter="transitioning = false"
				@before-leave="(el) => (el.style.opacity = 0)"
			>
				<router-view />
			</transition>
		</v-main>
		<LazyHydrate when-visible>
			<Footer />
		</LazyHydrate>
		<ActionBarMobile v-if="$b.m" />
		<BrandsDialog v-if="renderBrandsDialog" />
		<hook zone="bottom"></hook>
	</div>
</template>

<style lang="scss">
.v-main.transitioning {
	overflow: hidden;
}
.viewanim {
	&-enter-active {
		transition: opacity 0.5s, transform 0.25s;
	}
	&-enter {
		opacity: 0;
		transform: translateX(15%);
	}
	&-leave-active {
		transition: all 0s;
	}
	&-leave-to,
	&-leave {
		opacity: 0;
	}
}
</style>
