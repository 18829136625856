<script>
import productCardMixin from '@/v-shop/mixins/product-card-mixin'

export default {
	lang: 'shop',
	mixins: [productCardMixin],

	hooksKey: 'ProductCard',
	provide() {
		return {
			hookContainer: () => this,
		}
	},
	cssVars: {
		selector: '.product-card',
	},
}
</script>

<template>
	<!--  VERTICAL CARD -->
	<div :class="verticalWrapperClasses" v-if="verticalCard">
		<v-hover v-model="hover">
			<v-card
				:hover="!outOfStock ? true : false"
				@click="cardClick"
				@mouseover="firstImageHover = true"
				:ripple="false"
				elevation="2"
				:class="[$bem('product-card-design5-vertical'), { cardBorder: cardBorder }, verticalCardClasses]"
				:style="{ height: btnHover && hover ? hoverForBtn : '100%' }"
			>
				<div :class="[$bem('__image-cont'), { 'no-stock': outOfStock }]">
					<hook zone="image">
						<Media :class="$bem('__main-image')" :src="mainImage" width="100%" aspect-ratio="1" />
						<div v-if="secondImage && firstImageHover && imgHover" :class="$bem('__second-image-cont')">
							<Media
								:src="secondImage"
								width="100%"
								:class="[$bem('__second-image'), hover ? $bem('__second-image--show') : '']"
							/>
						</div>
					</hook>

					<hook zone="offer">
						<LimitedTimeOffer :product="product" v-if="hasLimitedTimeOffer" :class="$bem('__offer')" />
					</hook>

					<hook zone="event-icon">
						<CollectionEventIcon :product="product" :class="$bem('__event-icon')" />
					</hook>
				</div>

				<div
					:class="[
						$bem('__data-cont'),
						'd-flex flex-column mx-3',
						{ 'no-stock': outOfStock, 'h100 justify-space-between': btn },
					]"
				>
					<ProductCardPrice
						:product="product"
						:currency="currency"
						:priceAmount="price"
						:prevPriceAmount="prevPrice"
						:discountPct="discountPct"
						:containerClass="[
							'd-flex align-center line-clamp-1 px-1 mt-3',
							{ 'justify-center': alignment == 'center', 'justify-end': alignment == 'right' },
						]"
						:prevPriceClass="[$bem('__prev-price'), 'grey--text mr-2 font-0']"
						:priceClass="[$bem('__price'), 'price font-4']"
						:discountClass="[$bem('__discount'), 'discount pa-2']"
						:elementOrder="{ prevPrice: 1, price: 2 }"
					/>

					<hook zone="attrs&financingTags">
						<div :class="$bem('__financing-wrapper')">
							<div
								:class="[
									$bem('__financing'),
									'font-0 line-clamp-1 px-1 primary rounded',
									{
										'mb-2': !hasTextTag,
										'alignment-center': alignment == 'center',
										'alignment-right': alignment == 'right',
									},
								]"
								v-if="financingTagHtml"
								v-html="financingTagHtml"
							/>
						</div>
					</hook>

					<hook zone="textTags">
						<ProductCardText
							:textClass="[
								$bem('__text-tags'),
								'font-0 line-clamp-1 px-1 primary rounded mb-2',
								{
									'alignment-center': alignment == 'center',
									'alignment-right mt-1': alignment == 'right',
								},
							]"
							:conditional="hasTextTag"
							:text="textTag"
						/>
					</hook>

					<router-link class="card-anchor header" :to="productRoute">
						<hook zone="name">
							<ProductCardText
								:textClass="[
									$bem('__name'),
									'line-clamp-2 font-2 px-1 header',
									alignment == 'left'
										? 'text-start'
										: alignment == 'center'
										? 'text-center'
										: 'text-end',
								]"
								:text="product.name"
							/>
						</hook>
					</router-link>

					<hook zone="brand">
						<div :class="$bem('__brand-wrapper')">
							<ProductCardText
								:textClass="[
									$bem('__brand'),
									'font-0 line-clamp-1 px-1',
									alignment == 'left'
										? 'text-start'
										: alignment == 'center'
										? 'text-center'
										: 'text-end',
								]"
								:conditional="!!brand"
								:text="brand"
							/>
						</div>
					</hook>
					<ProductCardRating :product="product" />

					<div
						class="pa-3"
						v-if="hasBtn"
						:class="{
							btnHoverVertical: btnHover,
							'btnHoverVertical--show': btnHover && hover,
							z1: product.hasUniqueVariant && !product.info.customizationKey,
						}"
					>
						<hook zone="btn">
							<v-btn
								class="cta w100 rounded-md"
								:disabled="buttonText == 'Sin stock'"
								:loading="btnIsLoading"
								@click="btnClicked"
							>
								{{ buttonText | lang }}
							</v-btn>
						</hook>
					</div>
				</div>
				<hook zone="wishlist-toggler" :class="{ 'no-stock': outOfStock }">
					<ProductWishlistToggler
						:productId="product.id"
						:class="$bem('__wishlist-toggler')"
						style="z-index: 1"
					/>
				</hook>
			</v-card>
		</v-hover>
	</div>
	<!-- END OF VERTICAL CARD -->

	<!-- HORIZONTAL CARD FOR MOBILE SHOP -->
	<div class="w100" v-else v-click-outside="onLinkBlurMobile">
		<v-card
			@click="cardClick"
			:ripple="false"
			:class="[$bem('product-card-design5-horizontal'), { cardBorder: cardBorder }, horizontalCardClasses]"
		>
			<div
				:class="[
					$bem('__image-cont', 'col col-5 pa-0 d-flex flex-column justify-center'),
					{ 'no-stock': outOfStock },
				]"
			>
				<hook zone="imageHorizontal">
					<Media
						:class="$bem('__main-image', 'px-1')"
						:src="mainImage"
						width="100%"
						imgClass="product-card-design5-horizontal__image--smallWidth"
					/>
				</hook>

				<hook zone="offerHorizontal">
					<LimitedTimeOffer
						:product="product"
						textAlign="end"
						class="pa-1 line-clamp-1"
						:class="$bem('__offer')"
						v-if="hasLimitedTimeOffer"
					/>
				</hook>

				<hook zone="event-iconHorizontal">
					<CollectionEventIcon :product="product" :class="$bem('__event-icon')" imgWidth="25px" />
				</hook>
			</div>

			<div
				class="col col-7 py-0 pl-1 d-flex flex-column justify-center"
				:class="[$bem('__data-cont'), { 'no-stock': outOfStock, 'my-5': !hasBtn }]"
			>
				<ProductCardPrice
					:product="product"
					:currency="currency"
					:priceAmount="price"
					:prevPriceAmount="prevPrice"
					:discountPct="discountPct"
					:containerClass="['d-flex align-center line-clamp-1', { 'mt-5': !hasBtn }]"
					:prevPriceClass="['grey--text font-1 mr-2', $bem('__prev-price')]"
					:priceClass="[$bem('__price'), 'price font-6 line-clamp-1']"
					:discountClass="[$bem('__discount'), 'discount pa-2']"
					horizontalCard
					:elementOrder="{ prevPrice: 1, price: 2 }"
				/>

				<hook zone="attrs&financingTagsHorizontal">
					<div
						:class="[
							$bem('__financing'),
							'font-0 line-clamp-1 px-1 primary rounded',
							{
								'mb-1': !hasTextTag && discountPct,
							},
						]"
						v-if="financingTagHtml"
						v-html="financingTagHtml"
					/>
				</hook>

				<hook zone="textTagsHorizontal">
					<ProductCardText
						:textClass="[$bem('__text-tags'), 'font-0 line-clamp-1 px-1 primary rounded']"
						:conditional="hasTextTag"
						:text="textTag"
					/>
				</hook>

				<router-link :to="productRoute" custom v-slot="{ navigate, href }">
					<a class="card-anchor header" @click="onLinkClickMobile(navigate, $event)" :href="href">
						<hook zone="nameHorizontal">
							<ProductCardText
								:textClass="[$bem('__name'), 'line-clamp-2 font-3 header']"
								:text="product.name"
							/>
						</hook>
					</a>
				</router-link>

				<hook zone="brand">
					<ProductCardText
						:textClass="[$bem('__brand'), 'font-0 line-clamp-1', { 'pb-2': !hasBtn }]"
						:conditional="!!brand"
						:text="brand"
						:spacerHeight="26.19"
					/>
				</hook>

				<hook zone="wishlist-togglerHorizontal">
					<ProductWishlistToggler
						:productId="product.id"
						:class="[$bem('__wishlist-toggler'), { 'no-stock': outOfStock }]"
						style="z-index: 1"
						:height="30"
						:width="30"
					/>
				</hook>
				<ProductCardRating :product="product" />

				<div
					class="py-2"
					v-if="hasBtn"
					:class="{
						btnHoverHorizontal: btnHover,
						'btnHoverHorizontal--show': btnHover && showBtnHoverMobile,
						z1: product.hasUniqueVariant && !product.info.customizationKey,
					}"
				>
					<hook zone="btn">
						<v-btn
							class="cta w100 rounded-md"
							:disabled="buttonText == 'Sin stock'"
							:loading="btnIsLoading"
							@click="btnClicked"
						>
							{{ buttonText | lang }}
						</v-btn>
					</hook>
				</div>
			</div>
		</v-card>
	</div>
	<!-- END OF HORIZONTAL CARD FOR MOBILE SHOP -->
</template>

<style lang="scss" scoped>
.cardBorder {
	border: 1px solid #b2b2b2;
}

.no-stock {
	opacity: 0.4;
}

// VERTICAL CARD CLASSES
.product-card-design5-vertical-wrapper {
	min-height: 380px;
	width: 250px;

	&-forBtn {
		padding-bottom: 60px;
	}
}

.product-card-design5-vertical {
	position: relative;
	cursor: pointer;

	&__image-cont {
		position: relative;
	}

	&__second-image-cont {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__second-image {
		transition: opacity 0.3s;
		opacity: 0;
		&--show {
			opacity: 1;
		}
	}

	&__offer {
		position: absolute;
		bottom: -4px;
	}

	&__event-icon {
		position: absolute;
		bottom: -15px;
		left: 8px;
		z-index: 1;
		width: 40px;
	}

	&__name,
	&__brand {
		display: -webkit-box;
	}

	&__wishlist-toggler {
		position: absolute;
		top: 6px;
		right: 6px;
		z-index: 1;
	}

	&__name {
		min-height: 47.59px;
	}

	&__brand-wrapper {
		min-height: 18.19px;
	}

	&__financing-wrapper {
		min-height: 18.19px;
	}
}

::v-deep .product-card-design5-vertical {
	.alignment-center {
		position: relative;
		left: 50%;
		transform: translateX(-50%);
	}

	.alignment-right {
		float: right;
	}

	&__prev-price {
		column-gap: 8px;
	}

	&__discount {
		font-size: 1.1rem;
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		border-bottom-right-radius: 4px;
	}
}

// HORIZONTAL CARD CLASSES
.product-card-design5-horizontal {
	min-height: 200px;

	&__image-cont {
		position: relative;
		width: 200px;
	}

	&__offer {
		position: absolute;
		bottom: 8px;
		border-top-right-radius: 6px;
		border-bottom-right-radius: 6px;
	}

	&__event-icon {
		position: absolute;
		bottom: 4px;
		left: 6px;
		z-index: 1;
		width: 25px;
	}

	&__name,
	&__brand {
		display: -webkit-box;
	}

	&__wishlist-toggler {
		position: absolute;
		top: 7px;
		right: 6px;
		z-index: 1;
	}

	// &__name {
	// 	min-height: 53.19px;
	// }
}

::v-deep div.product-card-design5-horizontal {
	&__image--smallWidth {
		width: 80%;
		left: 50%;
		transform: translateX(-50%);
		height: auto;
	}

	&__prev-price {
		display: -webkit-box;
		column-gap: 8px;
	}

	&__discount {
		font-size: 1.1rem;
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		border-bottom-right-radius: 4px;
	}

	&__price {
		display: -webkit-box;
	}
}
</style>
