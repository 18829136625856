<script>
import scrollMixin from './scrollMixin'
import appbarHeightMixin from './appbarHeightMixin'

export default {
	mixins: [scrollMixin, appbarHeightMixin],
	metaInfo() {
		const categories = this.megaMenuCategories
		return {
			link: categories.map((item, i) => ({
				as: 'image',
				rel: 'preload',
				href: this.$media(item.img).src,
			})),
		}
	},
	props: {
		forceHover: Number,
		submenuStyle: Object,
		categoriesStyle: Object,
		submenuClass: [String, Object],
		closeOnScroll: Boolean,
		overlay: {
			type: Boolean,
			default: true,
		},
		noCalculatedWidth: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			megaMenuCategories: this.$srv('MegaMenuHorizontal', []),
			globalHover: false,
			hoverSubmenu: false,
			close: false,
			visibilityRef: 'megaMenuHorizontal',
			appbarPosition: this.$srv('AppBarConfig.position', 'center'),
		}
	},
	model: {
		prop: 'forceHover',
		event: 'updateForceHover',
	},
	watch: {
		globalHover(val) {
			this.$emit('updateForceHover', val)
		},
	},
	methods: {
		compareFn(elementRect) {
			return elementRect.top > -56 && elementRect.bottom <= window.innerHeight
		},
		onNotVisibleCb() {
			this.close = true
		},
		bgImageStyle(item) {
			if (!item.img) return null
			let { src } = this.$media(item.img)
			const styles = {
				backgroundImage: `url('${src}')`,
				backgroundPosition: 'right bottom',
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'contain',
			}
			return styles
		},
		updateHover(event, index) {
			if (this.closeOnScroll) this.close = !event
			this.globalHover = index
		},
		doToggle(hover, toggle) {
			if (this.$b.t && hover) toggle()
		},
	},
	computed: {
		itemsWithSubcategories() {
			return this.megaMenuCategories.filter((i) => {
				if (i.linkType == 'link') return false
				else if (i.selectType == 'brand' || i.selectType == 'url') return i.links?.length
				else return (!i.noAutoFillCategory && i.category?.children.length) || i.links?.length
			})
		},
		cMaxWidth() {
			const offset = 90
			const width = window.innerWidth - offset
			return width
		},
		widthStyle() {
			if (this.noCalculatedWidth || this.$ssr) return

			const offset = 100
			const width = window.innerWidth - offset

			if (this.$srv('AppBarConfig.type') === 'round') {
				return {
					width: '100%',
					'max-width': '1200px',
					left: '50%',
					marginLeft: '-600px',
				}
			}

			return {
				width: `${width}px`,
				'margin-left': `${width / -2}px`,
				left: '50%',
			}
		},
		appbarMenuOffset() {
			return this.$srv('AppBarConfig.type') === 'regular' ? 10 : 80
		},
		subChild() {
			return (child, n = 4) => child.children?.slice(0, n) || []
		},
		hasManySubChildren() {
			return (child, n = 4) => child.children?.length > n
		},
		linkTextStyle() {
			return `color: ${this.$vars.linktext}!important`
		},
	},
	cssVars: {
		selector: '.appbar__mega-menu',
	},
}
</script>

<template>
	<div
		ref="megaMenuHorizontal"
		class="appbar__mega-menu d-flex"
		:style="categoriesStyle"
		v-if="megaMenuCategories.length"
	>
		<div
			v-for="(item, index) of megaMenuCategories"
			:key="index"
			:class="[$bem('mega-menu'), 'px-0', 'd-flex']"
			min-width="200px"
		>
			<Hover
				v-slot="{ hover, toggle }"
				unhover-on-route-loading
				:open-delay="$b.d ? 250 : 0"
				:close-delay="$b.d ? 250 : 0"
				:value="globalHover"
				@updateModel="updateHover($event, index)"
			>
				<div>
					<SafeLink class="d-inline-block pt-3">
						<v-btn
							:color="$vars.basetext"
							class="h100"
							:class="[
								$bem('__underline'),
								{ [$bem('__underline--permanent')]: hover || forceHover == index },
							]"
							text
							depressed
							small
							@click="doToggle(hover, toggle)"
						>
							<v-list-item-title>
								{{
									item.selectType == 'brand'
										? item.brand.name
										: item.selectType == 'url'
										? item.name
										: item.category.name
								}}
							</v-list-item-title>
						</v-btn>
					</SafeLink>
					<div
						class="px-6 py-1 pb-6 rounded"
						:class="[$bem('__submenu'), submenuClass]"
						:style="[
							bgImageStyle(item),
							{ 'max-height': `${cMaxHeight}px!important` },
							widthStyle,
							submenuStyle,
						]"
						v-show="!close && (hover || forceHover === index)"
					>
						<!-- Title -->
						<div :class="$bem('__title-wrapper')">
							<CategoriesMegaMenu-CategoryTitle
								v-if="!item.disableTitle"
								:item="item"
								@click.native="toggle"
							/>
						</div>

						<v-row justify="start" class="pl-4 pt-0">
							<v-col cols="9">
								<div id="menu" class="pt-0">
									<div
										v-for="ordered of item.ordered"
										:key="ordered.posId"
										class="category mb-4 pr-2"
										:class="$bem('__children')"
									>
										<div v-if="ordered.type == 'link'">
											<SafeLink
												v-if="ordered.selectType == 'category'"
												class="font-1 font-weight-bold pb-1 link"
												:to="
													$shop.getShopRoute({
														categories: ordered.category.familyUrlNames,
													})
												"
											>
												<div class="ellipsis-text">
													{{ ordered.category.name }}
												</div>
											</SafeLink>
											<SafeLink
												v-if="ordered.selectType == 'brand'"
												class="font-1 font-weight-bold pb-1 link"
												:to="$shop.getShopRoute({ brand: ordered.brand.urlName })"
											>
												<div class="ellipsis-text">{{ ordered.brand.name }}</div>
											</SafeLink>
											<SafeLink
												v-else
												class="font-1 font-weight-bold pb-1 link"
												:to="ordered.link"
											>
												<div class="ellipsis-text">{{ ordered.name }}</div>
											</SafeLink>
											<div :class="$bem('__subcategories')">
												<div v-for="(child, n) in ordered.children" :key="n">
													<SafeLink
														v-if="child.selectType == 'category'"
														:to="
															$shop.getShopRoute({
																categories: child.category.familyUrlNames,
															})
														"
														class="font-weight-bold pb-1 link"
													>
														{{ child.category.name }}
													</SafeLink>
													<SafeLink
														v-if="child.selectType == 'brand'"
														:to="
															$shop.getShopRoute({
																brand: child.brand.urlName,
															})
														"
														class="font-weight-bold pb-1 link"
													>
														{{ child.brand.name }}
													</SafeLink>
													<SafeLink v-else :to="child.link" class="font-weight-bold pb-1 link">
														{{ child.name }}
													</SafeLink>
												</div>
											</div>
										</div>

										<div v-else>
											<SafeLink
												class="font-1 font-weight-bold pb-1 link"
												:to="
													$shop.getShopRoute({
														categories: ordered.familyUrlNames,
													})
												"
											>
												{{ ordered.name }}
											</SafeLink>
											<div :class="$bem('__subcategories')">
												<div v-for="(subChild, i) in subChild(ordered)" :key="i">
													<SafeLink
														:to="$shop.getShopRoute({ categories: subChild.familyUrlNames })"
														class="font-weight-bold pb-1 link"
													>
														{{ subChild.name }}
													</SafeLink>
												</div>
											</div>
											<SafeLink
												v-if="hasManySubChildren(ordered)"
												class="font-1 link"
												:to="$shop.getShopRoute({ categories: ordered.familyUrlNames })"
											>
												<span>Ver más</span>
											</SafeLink>
										</div>
									</div>
									<v-col>
										<SafeLink :to="item.link">
											<div :class="$bem('__image')"></div>
										</SafeLink>
									</v-col>
								</div>
							</v-col>
							<v-col>
								<SafeLink :to="item.link">
									<div :class="$bem('__image')"></div>
								</SafeLink>
							</v-col>
						</v-row>
					</div>
				</div>
			</Hover>
		</div>
		<!-- <CategoriesMegaMenu-Overlay v-if="overlay" v-show="globalHover" style="z-index: 1; left: -17px" /> -->
	</div>
</template>

<style lang="scss" scoped>
#menu {
	column-count: 3;
	column-gap: 10px;
}

.mega-menu {
	min-height: 52px;

	&__underline {
		&::after {
			content: ' ';
			position: absolute;
			left: 0;
			right: 0;
			border-bottom: 1px solid var(--basetext);
			bottom: 4px;
			margin-right: 10px;
			margin-left: 10px;
			transform: scaleX(0);
			transform-origin: bottom center;
			transition: transform 0.25s ease-out;
		}
		&:hover::after {
			transform: scaleX(1);
			transform-origin: bottom center;
		}
		&--permanent::after {
			transform: scaleX(1);
			transform-origin: bottom center;
		}
	}

	&__submenu {
		position: absolute;
		min-height: 350px;
		overflow: auto;
		background-color: var(--secondary);
		width: 100%;
		// margin-left: -50%;
		top: 52px;
		z-index: 3;

		&::-webkit-scrollbar {
			width: 3px;
		}

		&::-webkit-scrollbar-track {
			box-shadow: inset 0 0 6px rgba(145, 144, 144, 0.3);
		}

		&::-webkit-scrollbar-thumb {
			background-color: var(--base);
			outline: 1px solid rgb(143, 143, 144);
		}
	}

	&__title-wrapper {
		min-height: 53.39px;
	}

	&__children {
		break-inside: avoid-column;
	}

	&__subcategories {
		font-size: 13px;
	}

	&__children,
	&__subcategories {
		& a {
			opacity: 0.65;
			&:hover {
				opacity: 1;
			}
		}
	}

	&__image {
		position: absolute;
		width: 230px;
		height: 100%;
		background-color: transparent;
		right: 0;
		bottom: 0;
	}
}
</style>
