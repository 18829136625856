<script>
export default {
	data() {
		return { x: false }
	},
	mounted() {
		this.x = true
	},
	render() {
		return this.x ? this.$slots.default : null
	},
}
</script>
