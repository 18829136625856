<script>
import { get, sync } from 'vuex-pathify'

export default {
	data() {
		return {
			menuLinks: this.$srv('menuLinks'),
			showMegaMenu: this.$srv('AppBarConfig.showMegaMenu', false),
			showHorizontalMegaMenu: this.$srv('AppBarConfig.showHorizontalMegaMenu', false),
			useCustomizableMobileMenu: this.$srv('AppBarConfig.useCustomizableMobileMenu', false),
		}
	},
	hooksKey: 'MobileMenuDrawer',
	provide() {
		return {
			hookContainer: () => this,
		}
	},
	computed: {
		brandsDialog: sync('shop/brandsDialog'),
		routeLoading: get('shop/routeLoading'),
		menuMobileDrawer: sync('shop/menuMobileDrawer'),
		user: get('shop/user'),
	},
	methods: {
		closeDrawer() {
			this.menuMobileDrawer = false
		},
		closeConditional(e) {
			return this.menuMobileDrawer && !this.$store.get('shop/brandsDialog')
		},
		onClickOutside() {
			this.menuMobileDrawer = false
		},
		linkIsAbsolute(link) {
			const absoluteUrlRegex = /^([a-z]+:\/\/|(mailto|tel):)/i
			return typeof link === 'string' && link.match(absoluteUrlRegex)
		},
		getVBind(item) {
			if (this.linkIsAbsolute(item.link)) {
				return {
					href: item.link,
					target: '_blank',
				}
			} else {
				return {
					to: item.link,
				}
			}
		},
		showLoginDrawer() {
			this.menuMobileDrawer = false
			this.$nextTick(() => {
				this.$store.set('shop/loginDrawer', true)
			})
		},
	},
	watch: {
		routeLoading() {
			this.menuMobileDrawer = false
		},
		$route() {
			this.menuMobileDrawer = false
		},
	},
	cssVars: {
		selector: '.mobile-menu-drawer',
	},
}
</script>

<template>
	<v-navigation-drawer
		class="mobile-menu-drawer base drawer-index pa-0"
		v-model="menuMobileDrawer"
		width="500px"
		overlay-color="#000"
		temporary
		:overlay-opacity="0.8"
		fixed
		touchless
		stateless
		app
		v-click-outside="{
			handler: onClickOutside,
			closeConditional,
		}"
	>
		<div class="close-button d-flex justify-space-between pa-3 align-center" style="z-index: 2">
			<div class="font-weight-bold font-1">Menu</div>
			<Button :ripple="false" icon @click="menuMobileDrawer = false" fab small color="base">
				<v-icon large :color="$vars.basetext">mdi-close</v-icon>
			</Button>
		</div>

		<MobileMenuDrawer-CustomizableLinks
			v-if="useCustomizableMobileMenu"
			@show-login-drawer="showLoginDrawer"
		/>

		<div v-else>
			<v-list class="pt-15" color="base">
				<MobileMenuDrawer-MegaMenuCategories v-if="showMegaMenu" />

				<!-- SHOP LINK -->
				<v-list-item to="/shop">
					<v-list-item-icon>
						<v-icon :color="$vars.basetext">mdi-shopping</v-icon>
					</v-list-item-icon>

					<v-list-item-title :color="$vars.basetext" class="base font-1">Tienda</v-list-item-title>
				</v-list-item>

				<!-- MENU LINKS -->
				<div v-for="(item, i) of menuLinks" :key="i">
					<v-list-group v-if="item.children" append-icon="mdi-chevron-down" class="base">
						<template v-slot:prependIcon>
							<v-icon v-if="item.icon" class="ml-0" :color="$vars.basetext">{{ item.icon }}</v-icon>
						</template>
						<template v-slot:activator>
							<v-list-item-title :color="$vars.basetext" class="base font-1">
								{{ item.name }}
							</v-list-item-title>
						</template>
						<SafeLink
							is="v-list-item"
							v-bind="getVBind(subitem)"
							class="px-5 base"
							v-for="(subitem, index) of item.children"
							:key="index"
						>
							<v-list-item-title>
								{{ subitem.name }}
							</v-list-item-title>
						</SafeLink>
					</v-list-group>

					<SafeLink
						v-else-if="!item.type || item.type === 'link'"
						is="v-list-item"
						v-bind="getVBind(item)"
						class="base w100"
					>
						<v-list-item-icon v-if="item.icon">
							<v-icon :color="$vars.basetext">{{ item.icon }}</v-icon>
						</v-list-item-icon>

						<v-list-item-title :color="$vars.basetext" class="base font-1">{{
							item.name
						}}</v-list-item-title>
					</SafeLink>

					<v-list-item
						class="base w100"
						v-else-if="item.type && item.type === 'allBrands'"
						@click="brandsDialog = true"
					>
						<v-list-item-icon v-if="item.icon">
							<v-icon :color="$vars.basetext">{{ item.icon }}</v-icon>
						</v-list-item-icon>

						<v-list-item-title :color="$vars.basetext" class="base font-1">
							{{ item.name }}
						</v-list-item-title>
					</v-list-item>
				</div>

				<!-- USER ACCOUNT LINKS -->
				<div class="base font-1" v-if="user">
					<v-list-item :to="{ name: 'user.favorites' }">
						<v-list-item-icon>
							<v-icon :color="$vars.basetext">mdi-heart</v-icon>
						</v-list-item-icon>
						<v-list-item-title> Favoritos </v-list-item-title>
					</v-list-item>

					<v-list-item :to="{ name: 'user.account' }">
						<v-list-item-icon>
							<v-icon>mdi-account</v-icon>
						</v-list-item-icon>
						<v-list-item-title>
							{{ user.firstname }}
						</v-list-item-title>
					</v-list-item>
				</div>

				<v-list-item v-else :color="$vars.basetext" class="base">
					<v-list-item-icon>
						<v-icon :color="$vars.basetext">mdi-account</v-icon>
					</v-list-item-icon>

					<v-list-item-title @click="showLoginDrawer" class="font-1">Ingresar</v-list-item-title>
				</v-list-item>
				<!-- END USER ACCOUNT LINKS -->
			</v-list>
		</div>
	</v-navigation-drawer>
</template>

<style scoped lang="scss">
.close-button {
	width: 100%;
	position: fixed;
	backdrop-filter: blur(0.5rem);
	-webkit-backdrop-filter: blur(0.5rem);
}

/*div[class*='icon'] {
	margin-left: 0 !important;
}*/
.mobile-menu-drawer ::v-deep .v-icon {
	color: var(--basetext);
}
::v-deep .v-list-item__icon {
	margin-left: 0 !important;
}

// ::v-deep .v-list-item__title {
// 	font-size: 19px;
// }
</style>
