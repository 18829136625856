<script>
import productCardMixin from '@/v-shop/mixins/product-card-mixin'

export default {
	lang: 'shop',
	mixins: [productCardMixin],
	hooksKey: 'OfferItem',
	provide() {
		return {
			hookContainer: () => this,
		}
	},
	computed: {
		cardWithBtn() {
			let type = this.$srv('productCardType', {})
			if (this.$b.d) return type.btnDesktop == 'noBtn' ? false : true
			return type.btnMobile == 'noBtn' ? false : true
		},
	},

	cssVars: {
		selector: '.product-card',
	},
}
</script>

<template>
	<v-hover v-slot="{ hover }">
		<v-card
			outlined
			@click="cardClick"
			:ripple="false"
			class="d-flex overflow-hidden base product-card py-2"
			:class="$bem('offer-item')"
			:hover="!outOfStock ? true : false"
			@mouseover="firstImageHover = true"
		>
			<div :class="$bem('__image-cont')" v-if="!outOfStock">
				<Media :class="$bem('__main-image')" :src="mainImage" width="100%" aspect-ratio="1" />
				<div v-if="secondImage && firstImageHover" :class="$bem('__second-image-cont')">
					<Media
						:src="secondImage"
						width="100%"
						:class="[$bem('__second-image'), hover ? $bem('__second-image--show') : '']"
					/>
				</div>
			</div>
			<div :class="$bem('__image-cont', 'col col-5 pa-0 no-stock')" v-else>
				<Media :class="$bem('__main-image', 'px-1')" :src="mainImage" width="100%" />
			</div>

			<div
				class="col col-7 py-0 pl-1 d-flex flex-column justify-center"
				:class="{ 'no-stock': outOfStock }"
			>
				<PriceText
					:amount="price"
					:currency="currency"
					:class="$bem('__price')"
					class="price font-5 line-clamp-1 px-2"
				/>

				<div
					class="d-flex flex-wrap align-center pb-2 line-clamp-1 px-2"
					:class="$bem('__prev-price')"
					v-if="prevPrice"
				>
					<del class="grey--text">
						<PriceText
							:amount="prevPrice"
							:currency="currency"
							:currencyMargin="0"
							priceClass="grey--text font-2"
						/>
					</del>

					<hook zone="discount">
						<span
							:class="$bem('__discount')"
							class="d-inline-block discount font-2"
							v-if="discountPct > 0"
						>
							{{ discountPct }}% OFF
						</span>
					</hook>
				</div>

				<router-link class="card-anchor" :to="productRoute">
					<div
						:class="$bem('__name')"
						class="line-clamp-2 font-1 px-2 header font-weight-regular text-start"
					>
						{{ product.name }}
					</div>
				</router-link>

				<div class="px-2 pt-2" v-if="cardWithBtn">
					<v-btn class="cta w100 rounded-md" :disabled="buttonText == 'Sin stock'" small>
						{{ buttonText | lang }}
					</v-btn>
				</div>
			</div>
		</v-card>
	</v-hover>
</template>

<style scoped lang="scss">
.offer-item {
	width: 350px;

	&__image-cont {
		position: relative;
		width: 160px;
	}

	&__second-image-cont {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__second-image {
		transition: opacity 0.3s;
		opacity: 0;
		&--show {
			opacity: 1;
		}
	}

	&__main-image {
		left: 50%;
		transform: translateX(-50%);
	}

	&__prev-price {
		column-gap: 8px;
	}

	&__discount {
		padding: 2px 5px;
		border-radius: 4px;
	}
}

.no-stock {
	opacity: 0.4;
}

a.card-anchor:before {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	z-index: 2;
	left: 0;
}
</style>
