<script>
export default {
	props: {
		quickSearchEnabled: {
			type: Boolean,
			default: true,
		},
		searchInputPlaceholder: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			inputText: null,
			quickResults: [],
			searchId: 0,
			quickSearchLoading: false,
			quickSearchShown: false,
			stopSearch: false,
			lastText: null,
			over: false,
		}
	},
	methods: {
		goToShopWithSearchQuery() {
			this.quickSearchShown = false
			this.$shop.search(this.inputText)
		},
		getProductRoute(product) {
			return {
				name: 'product',
				params: {
					urlName: product.urlName,
					id: product.id,
				},
			}
		},
		runQuickSearch() {
			let text = this.inputText
			text = text ? text.trim() : ''
			this.searchId += 1

			if (text.length < 2 || this.stopSearch) {
				this.quickSearchShown = false
				return
			}

			this.quickSearchShown = true

			if (text == this.lastText) {
				this.quickSearchLoading = false
				return
			}
			this.quickSearchLoading = true
			let searchId = this.searchId
			setTimeout(() => {
				if (searchId != this.searchId) return
				this.$shopApi.post({
					url: '/catalog/shop',
					data: { filters: [process.env.VUE_APP_SHOP_ALL_KEYWORD], search: text, page: 1, limit: 5 },
					onSuccess: ({ data }) => {
						if (searchId != this.searchId) return
						this.lastText = text
						this.quickResults = data.shop.products
						this.quickSearchLoading = false
					},
				})
			}, 500)
		},
		onBlur() {
			if (this.over) return
			this.$nextTick(() => {
				setTimeout(() => (this.quickSearchShown = false), 100)
			})
		},
		onProductClick() {
			this.quickSearchShown = false
			this.over = false
			this.$emit('product:click')
		},
		variantForDisplay(product) {
			return product.variants.find((x) => x.forDisplay)
		},
	},
	eventsListeners: {
		'shop-list:loaded'() {
			let querySearch = this.$route.query?.search?.trim()
			if (querySearch) {
				this.inputText = querySearch
				this.stopSearch = true
				this.$nextTick(() => (this.stopSearch = false))
			}
		},
	},
	watch: {
		inputText() {
			this.runQuickSearch()
		},
	},
}
</script>
<template>
	<form
		class="d-flex justify-space-between white input-container relative"
		@submit.prevent="goToShopWithSearchQuery"
	>
		<input
			autocomplete="off"
			:value="inputText"
			@input="(event) => (inputText = event.target.value)"
			type="search"
			:placeholder="searchInputPlaceholder"
			class="pl-3 w100 font-0 font-sm-1"
			name="search"
			@focus="runQuickSearch()"
			@blur="onBlur()"
		/>
		<div
			class="quick-search pt-1"
			v-if="quickSearchEnabled && quickSearchShown && !stopSearch"
			@mouseenter="over = true"
			@mouseleave="over = false"
		>
			<div class="quick-search__results elevation-16 rounded overflow-hidden">
				<v-list dense>
					<v-subheader>Resultados para "{{ inputText }}"</v-subheader>
					<div v-if="quickSearchLoading">
						<v-skeleton-loader type="list-item-avatar"></v-skeleton-loader>
						<v-skeleton-loader type="list-item-avatar"></v-skeleton-loader>
						<v-skeleton-loader type="list-item-avatar"></v-skeleton-loader>
						<v-skeleton-loader type="list-item-avatar"></v-skeleton-loader>
					</div>
					<div v-else-if="quickResults.length">
						<v-list-item
							v-for="(product, i) in quickResults"
							:key="i"
							link
							:to="getProductRoute(product)"
							@click="onProductClick()"
						>
							<v-list-item-avatar>
								<Media :src="product.mainImage.mediumUrl" />
							</v-list-item-avatar>
							<v-list-item-content>
								<v-list-item-title v-text="product.name"></v-list-item-title>
								<PriceLayout
									class="d-flex flex-row-reverse justify-end mt-1"
									:prevPrice="variantForDisplay(product).pvPrice.prevPrice"
									:price="variantForDisplay(product).pvPrice.price"
									prev-price-class="font-0"
									price-class="color1--text font-1 mr-2"
									discount-text="-"
								/>
							</v-list-item-content>
						</v-list-item>
						<v-list-item @click="goToShopWithSearchQuery" class="d-flex justify-center align-center">
							<div class="color1--text text-decoration-underline">Ver todos los resultados</div>
						</v-list-item>
					</div>
					<div v-else class="text-center" @click="quickSearchShown = false">
						<div>Sin coincidencias</div>
						<router-link :to="$shop.getShopRoute()" class="color1--text text-decoration-underline">
							Ver todos los productos
						</router-link>
					</div>
				</v-list>
			</div>
		</div>
		<div class="d-flex align-center justify-center icon-container" @click="goToShopWithSearchQuery">
			<v-icon color="color2" size="30">mdi-magnify</v-icon>
		</div>
	</form>
</template>

<style scoped lang="scss">
.input-container {
	border-radius: 0.5rem;
}
.icon-container {
	width: 50px;
	height: 50px;
	border-radius: 0 0.5rem 0 1rem;
}
input {
	outline: none;
}
.quick-search {
	width: 100%;
	position: absolute;
	left: 0;
	bottom: 0;
	transform: translateY(100%);
	z-index: 100;
}
</style>
