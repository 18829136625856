var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"appbar base w100"},[_c('v-container',{staticClass:"d-flex align-center justify-space-between py-3",style:(_vm.containerStyle)},[(_vm.$b.m)?_c('v-btn',{staticClass:"ml-3",attrs:{"aria-label":"menu","icon":""},on:{"click":function($event){_vm.menuMobileDrawer = true}}},[_c('v-icon',{attrs:{"color":_vm.iconColor || _vm.$vars.basetext,"size":"30"}},[_vm._v("mdi-menu")])],1):_vm._e(),(
				!_vm.$b.m &&
				_vm.showMegaMenu &&
				_vm.megaMenuType === 'vertical' &&
				_vm.megaMenuLocation === 'top' &&
				_vm.config.roundAppBarMenuLocation === 'before'
			)?_c('CategoriesMegaMenu',{attrs:{"closeOnScroll":"","btnText":_vm.config.megaMenuBtnText,"dense":_vm.$b.t,"denseWidthScale":0.2,"widthScale":0.2,"submenuStyles":"width: 995px","iconPosition":_vm.iconPosition}}):_vm._e(),(!_vm.showMobileSearch)?_c('SafeLink',{attrs:{"to":"/"}},[_c('Media',{attrs:{"src":_vm.$b({ d: _vm.logo, mt: _vm.logoMobile })}})],1):_vm._e(),(
				!_vm.$b.m &&
				_vm.showMegaMenu &&
				_vm.megaMenuType === 'vertical' &&
				_vm.megaMenuLocation === 'top' &&
				_vm.config.roundAppBarMenuLocation === 'after'
			)?_c('CategoriesMegaMenu',{attrs:{"closeOnScroll":"","btnText":_vm.config.megaMenuBtnText,"dense":_vm.$b.t,"denseWidthScale":0.2,"widthScale":0.2,"submenuStyles":"width: 850px","iconPosition":_vm.iconPosition}}):_vm._e(),(_vm.$b.td)?_c('AutocompleteSearchField',{staticClass:"mx-3 w80 fixedHeight",attrs:{"closeOnScroll":"","iconContainerSize":{ width: '40px', height: '40px' }}}):_vm._e(),_c('AppBar-Buttons',{attrs:{"cartIconColor":_vm.cartIconColor}})],1),_c('AppBarMobileSearch',{class:_vm.mobileSearchClass,attrs:{"transparent":_vm.makeAppbarTransparent}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }