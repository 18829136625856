import { render, staticRenderFns } from "./comp_AppBar-Buttons.vue?vue&type=template&id=64d4ad70&scoped=true&"
import script from "./comp_AppBar-Buttons.vue?vue&type=script&lang=js&"
export * from "./comp_AppBar-Buttons.vue?vue&type=script&lang=js&"
import style0 from "./comp_AppBar-Buttons.vue?vue&type=style&index=0&id=64d4ad70&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64d4ad70",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBadge,VBtn,VIcon})
