import urlMatch from '#/v-shop/vue-src/utils/urlMatch'
import moment from 'moment'
import { get } from 'vuex-pathify'

export default {
	data() {
		return {
			whatsAppBtnConfig: this.$srv('whatsAppBtnConfig'),
			currentDay: new Date().toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase(),
		}
	},
	methods: {
		agentIsOnSchedule(agent) {
			return agent.active && agent[this.currentDay] && this.agentIsOnScheduleHours(agent)
		},
		agentIsOnScheduleHours(agent) {
			const start = agent[`${this.currentDay}ScheduleStart`]
			const end = agent[`${this.currentDay}ScheduleEnd`]

			const startTime = moment(start, 'HH:mm')
			const endTime = moment(end, 'HH:mm')

			return moment().isBetween(startTime, endTime)
		},
	},
	computed: {
		routeFullPath: get('route/fullPath'),
		whatsAppConfig() {
			return this.whatsAppBtnConfig
				?.filter((config) => urlMatch(config, this.routeFullPath))
				.reduce((acc, config) => {
					const agents = config.agents?.filter(this.agentIsOnSchedule)
					if (!agents.length) return acc
					config.agents = agents
					acc.push(config)
					return acc
				}, [])
		},
		hasActiveAgents() {
			return this.whatsAppConfig.some((c) => c.agents.length)
		},
	},
}
