<script>
import productCardMixin from '@/v-shop/mixins/product-card-mixin'
import shopProductOptions from '@/v-shop/mixins/shop-product-options-mixin'

export default {
	lang: 'shop',
	mixins: [productCardMixin, shopProductOptions()],

	hooksKey: 'ProductCard',
	provide() {
		return {
			hookContainer: () => this,
		}
	},
	cssVars: {
		selector: '.product-card',
	},
	computed: {
		wrapperClass() {
			if (this.$b.m) {
				if (!this.forMobileShop) {
					if (this.btnHover) return 'product-card-design6-small-wrapper-mobile-forBtn'
					else return 'product-card-design6-small-wrapper-mobile'
				} else {
					if (this.btnHover) return 'product-card-design6-small-wrapper-forBtn'
					else return 'product-card-design6-small-wrapper'
				}
			} else if (this.$b.t) {
				if (this.btnHover) return 'product-card-design6-small-wrapper-forBtn'
				else return 'product-card-design6-small-wrapper-mobile'
			} else return 'product-card-design6-small-wrapper'
		},
	},
}
</script>

<template>
	<!--  VERTICAL CARD -->
	<div
		class="h100"
		:class="wrapperClass"
		v-if="verticalCard"
		v-click-outside="forMobileShop && onLinkBlurMobile"
	>
		<v-hover v-model="hover">
			<v-card
				:hover="!outOfStock ? true : false"
				elevation="2"
				@mouseover="firstImageHover = true"
				@click="cardClick"
				:ripple="false"
				:class="[
					$bem('product-card-design6-small'),
					{ cardBorder: cardBorder, 'd-flex flex-column justify-space-between': !spoHideBuyBtn },
				]"
				class="rounded-md overflow-hidden base product-card"
				:style="{ height: forMobileShop && btnHover && hover ? 'calc(100% + 50px)' : '100%' }"
			>
				<div :class="[$bem('__image-cont'), { 'no-stock': outOfStock }, 'mx-2 mt-2']">
					<hook zone="image">
						<Media
							cover
							:class="$bem('__main-image')"
							:src="mainImage"
							width="100%"
							aspect-ratio="1"
							:imgClass="
								$b.mt
									? 'product-card-design6-small__image--smallWidth-mobile'
									: 'product-card-design6-small__image--smallWidth'
							"
						/>
						<div v-if="secondImage && firstImageHover && imgHover" :class="$bem('__second-image-cont')">
							<Media
								cover
								:src="secondImage"
								width="100%"
								:class="[$bem('__second-image'), hover ? $bem('__second-image--show') : '']"
								:imgClass="
									$b.mt
										? 'product-card-design6-small__image--smallWidth-mobile'
										: 'product-card-design6-small__image--smallWidth'
								"
							/>
						</div>
					</hook>

					<hook zone="attrs&financingTags">
						<div
							:class="[
								$bem('__financing'),
								'font-0 line-clamp-1 px-1 primary rounded',
								alignment == 'right'
									? 'product-card-design6-small__financing--alignment-right'
									: alignment == 'center'
									? 'alignment-center'
									: 'product-card-design6-small__financing--alignment-left',
							]"
							v-html="financingTagHtml"
							v-if="financingTagHtml"
						/>
					</hook>

					<hook zone="offer">
						<LimitedTimeOffer :product="product" v-if="hasLimitedTimeOffer" :class="$bem('__offer')" />
					</hook>

					<CollectionEventIcon :product="product" :class="$bem('__event-icon')" imgWidth="25px" />
				</div>

				<div
					:class="[
						$bem('__data-cont'),
						'mx-2 mt-2',
						{
							'no-stock': outOfStock,
							'justify-space-between': btn && forMobileShop,
							'd-flex flex-column h100': !spoHideBuyBtn,
							h125: $b.d && spoHideBuyBtn,
						},
					]"
				>
					<ProductCardPrice
						:class="$bem('__price-container')"
						:product="product"
						:currency="currency"
						:priceAmount="price"
						:prevPriceAmount="prevPrice"
						:discountPct="discountPct"
						:containerClass="[
							$bem('__prev-price'),
							'd-flex align-baseline pb-1 line-clamp-1 px-1',
							{ 'justify-center': alignment == 'center', 'justify-end': alignment == 'right' },
						]"
						prevPriceClass="grey--text font-0"
						:priceClass="[
							$bem('__price'),
							'price font-3 line-clamp-1  mt-2 px-1',
							{
								'text-center': alignment == 'center',
								'text-end': alignment == 'right',
							},
						]"
						:discountClass="[$bem('__discount'), 'd-inline-block discount font-0 ml-1']"
						prevPriceGroup
					/>

					<hook zone="textTags">
						<ProductCardText
							:textClass="[
								$bem('__text-tags'),
								'font-0 line-clamp-1 px-1 primary rounded mb-1',
								{
									'alignment-center': alignment == 'center',
									'alignment-right': alignment == 'right',
								},
							]"
							:conditional="hasTextTag"
							:text="textTag"
						/>
					</hook>

					<router-link :to="productRoute" custom v-slot="{ navigate, href }">
						<a
							class="card-anchor header"
							@click="forMobileShop ? onLinkClickMobile(navigate, $event) : navigate($event)"
							:href="href"
						>
							<hook zone="name">
								<ProductCardText
									:textClass="[
										$bem('__name'),
										'line-clamp-2 font-1 header px-1',
										{ 'mb-2': !brand && !mobileBtn },
										alignment == 'left'
											? 'text-start'
											: alignment == 'center'
											? 'text-center'
											: 'text-end',
									]"
									:text="product.name"
								/>
							</hook>
						</a>
					</router-link>

					<hook zone="brand">
						<div :class="$bem('__brand-wrapper')">
							<ProductCardText
								:textClass="[
									$bem('__brand'),
									'font-0 line-clamp-1 px-1',
									{ 'mb-2': !mobileBtn },
									alignment == 'left'
										? 'text-start'
										: alignment == 'center'
										? 'text-center'
										: 'text-end',
								]"
								:conditional="!!brand"
								:text="brand"
							/>
						</div>
					</hook>
					<ProductCardRating :product="product" />

					<div
						class="pa-3"
						v-if="mobileBtn && !$b.d && hasBtn"
						:class="{
							btnHoverVertical: btnHover && forMobileShop,
							'btnHoverVertical--show': btnHover && hover && forMobileShop,
							z1: product.hasUniqueVariant && !product.info.customizationKey,
						}"
					>
						<hook zone="btn">
							<v-btn
								class="cta w100 rounded-md"
								:disabled="buttonText == 'Sin stock'"
								:loading="btnIsLoading"
								@click="btnClicked"
							>
								{{ buttonText | lang }}
							</v-btn>
						</hook>
					</div>
				</div>
			</v-card>
		</v-hover>
	</div>
	<!-- END OF VERTICAL CARD -->

	<!-- HORIZONTAL CARD -->
	<div
		class="w100"
		v-else
		:class="{
			'product-card-design6-small-horizontal-wrapper-forBtn': btnHover,
		}"
		v-click-outside="forMobileShop && onLinkBlurMobile"
	>
		<v-hover v-model="hover">
			<v-card
				:hover="!outOfStock ? true : false"
				@mouseover="firstImageHover = true"
				outlined
				@click="cardClick"
				:ripple="false"
				:class="[
					$bem('product-card-design6-small-horizontal'),
					{ 'product-card-design6-small-horizontal-forBtn': hasBtn, cardBorder: cardBorder },
				]"
				class="d-flex overflow-hidden base product-card py-2"
			>
				<div
					:class="[
						$bem('__image-cont', 'col col-5 pa-0 d-flex flex-column justify-center'),
						{ 'no-stock': outOfStock },
					]"
				>
					<hook zone="imageHorizontal" class="h100 p-relative">
						<Media
							:class="$bem('__main-image', 'px-1 h100')"
							:src="mainImage"
							width="100%"
							imgClass="product-card-design6-small-horizontal__image--smallWidth"
						/>

						<div
							v-if="secondImage && firstImageHover && imgHover"
							:class="$bem('__second-image-cont', 'px-1 h100')"
						>
							<Media
								:src="secondImage"
								width="100%"
								:class="[
									$bem('__second-image', 'h100'),
									hover ? $bem('__second-image--show') : '',
									'h100',
								]"
								imgClass="product-card-design6-small-horizontal__image--smallWidth"
							/>
						</div>
					</hook>

					<hook zone="offerHorizontal">
						<LimitedTimeOffer
							:product="product"
							textAlign="start"
							class="mx-2 rounded line-clamp-1 font-weight-regular"
							style="width: inherit; padding: 0 4px !important"
						/>
					</hook>
				</div>

				<div
					class="col col-7 py-0 pl-1 flex-column mt-2"
					:class="[
						$bem('__data-cont'),
						{
							'no-stock': outOfStock,
							'justify-space-between': hasBtn && $b.m && !forMobileShop,
							h100: $b.m && !forMobileShop,
							'justify-center': !hasBtn,
						},
					]"
				>
					<ProductCardPrice
						:class="$bem('__price-container')"
						:product="product"
						:currency="currency"
						:priceAmount="price"
						:prevPriceAmount="prevPrice"
						:discountPct="discountPct"
						:containerClass="[$bem('__prev-price'), 'd-flex align-center pb-2 line-clamp-1']"
						prevPriceClass="grey--text font-0"
						:priceClass="[$bem('__price'), 'price font-4 line-clamp-1 mt-1', { 'mt-3': hasBtn }]"
						:discountClass="[$bem('__discount'), 'd-inline-block discount font-0']"
						prevPriceGroup
						horizontalCard
					/>

					<hook zone="textTagsHorizontal">
						<ProductCardText
							:textClass="[$bem('__text-tags'), 'font-0 line-clamp-1 px-1 primary rounded']"
							:conditional="hasTextTag"
							:text="textTag"
						/>
					</hook>

					<router-link :to="productRoute" custom v-slot="{ navigate, href }">
						<a
							class="card-anchor header"
							@click="forMobileShop ? onLinkClickMobile(navigate, $event) : navigate($event)"
							:href="href"
						>
							<hook zone="nameHorizontal">
								<ProductCardText
									:textClass="[$bem('__name'), 'line-clamp-2 font-2 header', ,]"
									:text="product.name"
								/>
							</hook>
						</a>
					</router-link>

					<hook zone="brand">
						<div :class="$bem('__brand-wrapper')">
							<ProductCardText
								:textClass="[$bem('__brand'), 'font-0 line-clamp-1']"
								:conditional="!!brand"
								:text="brand"
							/>
						</div>
					</hook>
					<ProductCardRating :product="product" />

					<div
						class="py-1"
						v-if="hasBtn && mobileBtn"
						:class="{
							btnHoverHorizontal: btnHover && forMobileShop,
							'btnHoverHorizontal--show': (btnHover && showBtnHoverMobile) || (btnHover && hover),
							z1: product.hasUniqueVariant && !product.info.customizationKey,
						}"
					>
						<hook zone="btn">
							<v-btn
								class="cta w100 rounded-md"
								:disabled="buttonText == 'Sin stock'"
								:loading="btnIsLoading"
								@click="btnClicked"
							>
								{{ buttonText | lang }}
							</v-btn>
						</hook>
					</div>

					<hook zone="attrs&financingTagsHorizontal">
						<div
							:class="[$bem('__financing'), 'font-0 line-clamp-1 px-1 primary rounded mr-10']"
							v-if="financingTagHtml"
							v-html="financingTagHtml"
						/>
					</hook>

					<hook zone="event-iconHorizontal">
						<CollectionEventIcon :product="product" :class="$bem('__event-icon')" imgWidth="30px" />
					</hook>
				</div>
			</v-card>
		</v-hover>
	</div>
	<!-- END OF HORIZONTAL CARD -->
</template>

<style lang="scss" scoped>
.h125 {
	height: 125px;
}
.cardBorder {
	border: 1px solid #b2b2b2;
}
.no-stock {
	opacity: 0.4;
}

// VERTICAL CARD CLASSES
.product-card-design6-small-wrapper {
	min-height: 270px;
	width: 100%;
	min-width: 100px;

	&-forBtn {
		min-height: 270px;
		width: 100%;
		min-width: 100px;
		padding-bottom: 50px;
	}
}

.product-card-design6-small-wrapper-mobile {
	width: 90%;

	&-forBtn {
		width: 90%;
	}
}

.product-card-design6-small {
	position: relative;
	cursor: pointer;

	&__image-cont {
		position: relative;
	}

	&__second-image-cont {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__second-image {
		transition: opacity 0.3s;
		opacity: 0;
		&--show {
			opacity: 1;
		}
	}

	&__financing {
		position: absolute !important;
		top: 5px;

		&--alignment-right {
			right: 0;
			margin-left: 10px;
		}

		&--alignment-left {
			margin-right: 10px;
			left: 0;
		}
	}

	&__offer {
		position: absolute;
		bottom: 0;
	}

	&__event-icon {
		position: absolute;
		top: 60%;
		right: 5px;
		z-index: 1;
		width: 25px;
	}

	&__discount {
		padding: 2px 5px;
		border-radius: 4px;
	}

	&__name,
	&__brand {
		display: -webkit-box;
	}

	&__name {
		min-height: 42.84px;
	}

	&__price-container {
		min-height: 61.78px;
	}

	&__brand-wrapper {
		min-height: 18.19px;
	}
}

::v-deep .product-card-design6-small {
	.alignment-center {
		position: relative;
		left: 50%;
		transform: translateX(-50%);
	}

	.alignment-right {
		float: right;
	}

	&__discount {
		padding: 2px 5px;
		border-radius: 4px;
	}

	&__price {
		display: -webkit-box;
	}

	&__image--smallWidth {
		min-width: 180px;
		min-height: 180px;
		width: 100%;
		height: 100%;
		left: 50% !important;
		top: 50% !important;
		transform: translate(-50%, -50%) !important;
	}

	&__image--smallWidth-mobile {
		max-width: 170px;
		max-height: 170px;
		width: 100%;
		left: 50% !important;
		top: 50% !important;
		transform: translate(-50%, -50%) !important;
	}
}

// HORIZONTAL CARD CLASSES
.product-card-design6-small-horizontal {
	height: 200px;

	&-forBtn {
		height: 240px;
	}

	&__image-cont {
		position: relative;
		width: 200px;
	}

	&__second-image-cont {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
	}

	&__second-image {
		transition: opacity 0.3s;
		opacity: 0;
		&--show {
			opacity: 1;
		}
	}

	&__financing {
		position: absolute !important;
		top: 5px;
		left: 5px;
		margin-right: 10px;
	}

	&__event-icon {
		position: absolute;
		top: 4px;
		right: 10px;
		z-index: 1;
		width: 25px;
	}

	&__name,
	&__brand {
		display: -webkit-box;
	}

	&__name {
		min-height: 48.55px;
	}

	&__price-container {
		min-height: 60.58px;
	}
}

::v-deep div.product-card-design6-small-horizontal {
	&__image--smallWidth {
		width: 80%;
		left: 50%;
		transform: translateX(-50%);
		height: auto;
	}

	&__prev-price {
		display: -webkit-box;
		column-gap: 8px;
	}

	&__discount {
		padding: 2px 5px;
		border-radius: 4px;
	}

	&__price {
		display: -webkit-box;
	}
}
</style>
