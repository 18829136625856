<script>
import productCardMixin from '@/v-shop/mixins/product-card-mixin'
import shopProductOptions from '@/v-shop/mixins/shop-product-options-mixin'

export default {
	lang: 'shop',
	mixins: [productCardMixin, shopProductOptions()],

	hooksKey: 'ProductCard',
	provide() {
		return {
			hookContainer: () => this,
		}
	},
	cssVars: {
		selector: '.product-card',
	},
}
</script>

<template>
	<!--  VERTICAL CARD -->
	<div :class="verticalWrapperClasses" v-if="verticalCard">
		<v-hover v-model="hover">
			<v-card
				:hover="!outOfStock ? true : false"
				@click="cardClick"
				@mouseover="firstImageHover = true"
				:ripple="false"
				elevation="2"
				:class="[$bem('product-card-design6-vertical'), { cardBorder: cardBorder }, verticalCardClasses]"
				:style="{ height: btnHover && hover ? hoverForBtn : '100%' }"
			>
				<div :class="[$bem('__image-cont'), { 'no-stock': outOfStock }]">
					<hook zone="image">
						<Media :class="$bem('__main-image')" :src="mainImage" width="100%" aspect-ratio="1" />
						<div v-if="secondImage && firstImageHover && imgHover" :class="$bem('__second-image-cont')">
							<Media
								:src="secondImage"
								width="100%"
								:class="[$bem('__second-image'), hover ? $bem('__second-image--show') : '']"
							/>
						</div>
					</hook>

					<hook zone="attrs&financingTags">
						<div
							:class="[
								$bem('__financing'),
								'font-0 line-clamp-1 px-1 primary rounded',
								alignment == 'right'
									? 'product-card-design6-vertical__financing--alignment-right'
									: alignment == 'center'
									? 'alignment-center'
									: 'product-card-design6-vertical__financing--alignment-left',
							]"
							v-if="financingTagHtml"
							v-html="financingTagHtml"
						/>
					</hook>

					<hook zone="offer">
						<LimitedTimeOffer :product="product" v-if="hasLimitedTimeOffer" :class="$bem('__offer')" />
					</hook>

					<hook zone="event-icon">
						<CollectionEventIcon :product="product" :class="$bem('__event-icon')" />
					</hook>
				</div>

				<div
					:class="[
						$bem('__data-cont'),
						'd-flex flex-column mx-3',
						{ 'no-stock': outOfStock, 'h100 justify-space-between': btn, 'mt-2': spoHidePrice },
					]"
				>
					<ProductCardPrice
						:class="$bem('__price-container')"
						:product="product"
						:currency="currency"
						:priceAmount="price"
						:prevPriceAmount="prevPrice"
						:discountPct="discountPct"
						:containerClass="[
							$bem('__prev-price'),
							'd-flex align-center pb-2 line-clamp-1 px-1',
							{ 'justify-center': alignment == 'center', 'justify-end': alignment == 'right' },
						]"
						prevPriceClass="grey--text font-2"
						:priceClass="[
							$bem('__price'),
							'price font-6 line-clamp-1 mt-2 px-1',
							{
								'text-center': alignment == 'center',
								'text-end': alignment == 'right',
							},
						]"
						:discountClass="[$bem('__discount'), 'd-inline-block discount font-2']"
						prevPriceGroup
					/>

					<hook zone="textTags">
						<ProductCardText
							:textClass="[
								$bem('__text-tags'),
								'font-0 line-clamp-1 px-1 primary rounded mb-1',
								{
									'alignment-center': alignment == 'center',
									'alignment-right': alignment == 'right',
								},
							]"
							:conditional="hasTextTag"
							:text="textTag"
						/>
					</hook>

					<router-link class="card-anchor header" :to="productRoute">
						<hook zone="name">
							<ProductCardText
								:textClass="[
									$bem('__name'),
									'line-clamp-2 font-2 px-1 header',
									alignment == 'left'
										? 'text-start'
										: alignment == 'center'
										? 'text-center'
										: 'text-end',
									{ 'mb-2': !brand && !hasBtn },
								]"
								:text="product.name"
							/>
						</hook>
					</router-link>

					<hook zone="brand">
						<div :class="$bem('__brand-wrapper')">
							<ProductCardText
								:textClass="[
									$bem('__brand'),
									'font-0 line-clamp-1 px-1',
									alignment == 'left'
										? 'text-start'
										: alignment == 'center'
										? 'text-center'
										: 'text-end',
									{ 'mb-2': !hasBtn },
								]"
								:conditional="!!brand"
								:text="brand"
							/>
						</div>
					</hook>

					<ProductCardRating :product="product" />

					<div
						class="pa-3"
						v-if="hasBtn"
						:class="{
							btnHoverVertical: btnHover,
							'btnHoverVertical--show': btnHover && hover,
							z1: product.hasUniqueVariant && !product.info.customizationKey,
						}"
					>
						<hook zone="btn">
							<v-btn
								class="cta w100 rounded-md"
								:disabled="buttonText == 'Sin stock'"
								:loading="btnIsLoading"
								@click="btnClicked"
							>
								{{ buttonText | lang }}
							</v-btn>
						</hook>
					</div>
				</div>
			</v-card>
		</v-hover>
	</div>
	<!-- END OF VERTICAL CARD -->

	<!-- HORIZONTAL CARD FOR MOBILE SHOP -->
	<div class="w100" v-else v-click-outside="onLinkBlurMobile">
		<v-card
			@click="cardClick"
			:ripple="false"
			:class="[$bem('product-card-design6-horizontal'), { cardBorder: cardBorder }, horizontalCardClasses]"
		>
			<div
				:class="[
					$bem('__image-cont', 'col col-5 pa-0 d-flex flex-column justify-center'),
					{ 'no-stock': outOfStock },
				]"
			>
				<hook zone="imageHorizontal">
					<Media
						:class="$bem('__main-image', 'px-1')"
						:src="mainImage"
						width="100%"
						imgClass="product-card-design6-horizontal__image--smallWidth"
					/>
				</hook>

				<hook zone="attrs&financingTags">
					<div
						:class="[$bem('__financing'), 'font-0 line-clamp-1 px-1 primary rounded']"
						v-if="financingTagHtml"
						v-html="financingTagHtml"
					/>
				</hook>

				<hook zone="offerHorizontal">
					<LimitedTimeOffer
						:product="product"
						textAlign="start"
						class="mx-2 rounded line-clamp-1"
						style="width: inherit; padding: 0 4px !important"
						v-if="hasLimitedTimeOffer"
					/>
				</hook>
			</div>

			<div
				class="col col-7 py-0 pl-1 d-flex flex-column justify-center"
				:class="[$bem('__data-cont'), { 'no-stock': outOfStock }]"
			>
				<ProductCardPrice
					:class="$bem('__price-container')"
					:currency="currency"
					:priceAmount="price"
					:prevPriceAmount="prevPrice"
					:discountPct="discountPct"
					:containerClass="[$bem('__prev-price'), 'd-flex align-center pb-2 line-clamp-1']"
					prevPriceClass="grey--text font-1"
					:priceClass="[$bem('__price'), 'price font-6 line-clamp-1', { 'mt-3': hasBtn }]"
					:discountClass="[$bem('__discount'), 'd-inline-block discount font-1']"
					prevPriceGroup
					horizontalCard
				/>

				<hook zone="textTagsHorizontal">
					<ProductCardText
						:textClass="[$bem('__text-tags'), 'font-0 line-clamp-1 px-1 primary rounded']"
						:conditional="hasTextTag"
						:text="textTag"
					/>
				</hook>

				<router-link :to="productRoute" custom v-slot="{ navigate, href }">
					<a class="card-anchor header" @click="onLinkClickMobile(navigate, $event)" :href="href">
						<hook zone="nameHorizontal">
							<ProductCardText
								:textClass="[$bem('__name'), 'line-clamp-2 font-3 header']"
								:text="product.name"
							/>
						</hook>
					</a>
				</router-link>

				<hook zone="brand">
					<ProductCardText
						:textClass="[$bem('__brand'), 'font-0 line-clamp-1']"
						:conditional="brand"
						:text="brand"
					/>
				</hook>

				<ProductCardRating :product="product" />
				<div
					class="py-2"
					v-if="hasBtn"
					:class="{
						btnHoverHorizontal: btnHover,
						'btnHoverHorizontal--show': btnHover && showBtnHoverMobile,
						z1: product.hasUniqueVariant && !product.info.customizationKey,
					}"
				>
					<hook zone="btn">
						<v-btn
							class="cta w100 rounded-md"
							:disabled="buttonText == 'Sin stock'"
							:loading="btnIsLoading"
							@click="btnClicked"
						>
							{{ buttonText | lang }}
						</v-btn>
					</hook>
				</div>

				<hook zone="event-iconHorizontal">
					<CollectionEventIcon :product="product" :class="$bem('__event-icon')" imgWidth="25px" />
				</hook>
			</div>
		</v-card>
	</div>
	<!-- END OF HORIZONTAL CARD FOR MOBILE SHOP -->
</template>

<style lang="scss" scoped>
.cardBorder {
	border: 1px solid #b2b2b2;
}

.no-stock {
	opacity: 0.4;
}

// VERTICAL CARD CLASSES
.product-card-design6-vertical-wrapper {
	min-height: 380px;
	width: 250px;

	&-forBtn {
		padding-bottom: 60px;
	}
}

.product-card-design6-vertical {
	position: relative;
	cursor: pointer;

	&__image-cont {
		position: relative;
	}

	&__second-image-cont {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__second-image {
		transition: opacity 0.3s;
		opacity: 0;
		&--show {
			opacity: 1;
		}
	}

	&__financing {
		position: absolute !important;
		top: 9px;

		&--alignment-right {
			right: 10px;
			margin-left: 10px;
		}

		&--alignment-left {
			margin-right: 10px;
			left: 10px;
		}
	}

	&__offer {
		position: absolute;
		bottom: -4px;
	}

	&__event-icon {
		position: absolute;
		bottom: -15px;
		left: 8px;
		z-index: 1;
		width: 40px;
	}
	&__name,
	&__brand {
		display: -webkit-box;
	}

	&__name {
		min-height: 47.59px;
	}

	&__price-container {
		min-height: 78.8px;
	}

	&__brand-wrapper {
		min-height: 18.19px;
	}
}

::v-deep .product-card-design6-vertical {
	.alignment-center {
		position: relative;
		left: 50%;
		transform: translateX(-50%);
	}

	.alignment-right {
		float: right;
	}

	&__prev-price {
		column-gap: 8px;
	}

	&__discount {
		padding: 2px 3px;
		border-radius: 4px;
	}

	&__price {
		display: -webkit-box;
	}
}

// HORIZONTAL CARD CLASSES
.product-card-design6-horizontal {
	min-height: 200px;

	&__image-cont {
		position: relative;
		width: 200px;
	}

	&__event-icon {
		position: absolute;
		top: 4px;
		right: 6px;
		z-index: 1;
		width: 25px;
	}

	&__financing {
		position: absolute;
		top: 4px;
		left: 6px;
		margin-right: 10px;
	}

	&__name,
	&__brand {
		display: -webkit-box;
	}

	// &__price-container {
	// 	min-height: 68px;
	// }
}

::v-deep div.product-card-design6-horizontal {
	&__image--smallWidth {
		width: 80%;
		left: 50%;
		transform: translateX(-50%);
		height: auto;
	}

	&__prev-price {
		display: -webkit-box;
		column-gap: 8px;
	}

	&__discount {
		padding: 2px 5px;
		border-radius: 4px;
	}

	&__price {
		display: -webkit-box;
	}
}
</style>
