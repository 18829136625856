<script>
import { get } from 'vuex-pathify'
import productMixin from '@/v-shop/mixins/product-mixin'
import shouldLoginMixin from '@/v-shop/mixins/should-login-mixin'

export default {
	mixins: [productMixin('page'), shouldLoginMixin],
	props: {
		product: Object,
		initialReviews: Array,
	},
	data() {
		return {
			createReviewDialog: false,
			iInitialReviews: this.initialReviews,
			loadingInitialReviews: false,
			reviewsCount: [],
			loadingReviewsCount: false,
			scrollIntoView: false,
			allReviewsDialog: false,
			initialReviewsQty: this.$srv('reviewsConfig.productPageInitialReviewsQty', 5),
		}
	},
	computed: {
		user: get('shop/user'),
	},
	watchStore: {
		'shop/loginDrawer': {
			once: true,
			handler(v) {
				if (!v) {
					this.createReviewDialog = true
				}
			},
		},
	},
	methods: {
		openCreateReviewDialog() {
			if (this.user) this.createReviewDialog = true
			else {
				this.shouldLogin(() => {
					this.createReviewDialog = true
				})
			}
		},
		loadInitialReviews() {
			this.$shopApi.get({
				url: '/catalog/product-reviews',
				loading: (v) => (this.loadingInitialReviews = v),
				query: {
					filters: {
						sku: this.selectedVariant.sku,
						ean: this.selectedVariant.ean,
						sellerId: this.product.sellerId,
					},
					page: 1,
					itemsPerPage: this.initialReviewsQty,
				},
				onSuccess: ({ data }) => {
					this.iInitialReviews = data.reviews
				},
			})
		},
		loadCount() {
			this.$shopApi.get({
				url: '/catalog/product-reviews-count',
				loading: (v) => (this.loadingReviewsCount = v),
				query: {
					sku: this.selectedVariant.sku,
					ean: this.selectedVariant.ean,
					sellerId: this.product.sellerId,
				},
				onSuccess: ({ data }) => {
					console.log(data)
					this.reviewsCount = data.reviewsCount.sort((a, b) => b.rating - a.rating)
				},
			})
		},
		onIntersect() {
			this.scrollIntoView = true
			this.loadCount()
			if (!this.iInitialReviews) this.loadInitialReviews()
		},
	},
}
</script>

<template>
	<div v-intersect.once="onIntersect">
		<template v-if="initialReviews || scrollIntoView">
			<v-card>
				<ProductReviews
					:product="product"
					:reviews="iInitialReviews"
					:loading-reviews="loadingInitialReviews"
					:reviews-count="reviewsCount"
					:loading-reviews-count="loadingReviewsCount"
					:total-reviews="product.ratingCount"
					:skeleton-loader-qty="initialReviewsQty"
					@create-review="openCreateReviewDialog"
				>
					<template #below-reviews v-if="iInitialReviews && iInitialReviews.length">
						<Button text class="link--text mt-4" @click="allReviewsDialog = true">
							Ver todas las opiniones
						</Button>
					</template>
				</ProductReviews>
			</v-card>

			<UserReviewDialog
				v-model="createReviewDialog"
				:review="{
					title: product.name,
					image: mainImage,
					sku: selectedVariant.sku,
					ean: selectedVariant.ean,
					sellerId: product.sellerId,
				}"
			/>

			<AllReviewsDialog
				v-model="allReviewsDialog"
				:reviews-count="reviewsCount"
				:product="product"
				@create-review="openCreateReviewDialog"
			/>
		</template>
	</div>
</template>
