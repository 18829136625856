import { views } from '@/v-shop/autoloader'

export default ({ srv }) => {
	return srv('dynamicPagesRoutes', []).map((data) => {
		let route = {
			path: data.path,
			meta: data.meta,
		}

		if (data.meta.blank) {
			route.meta.appComponent = views.DynamicPage
		} else {
			route.component = views.DynamicPage
		}
		return route
	})
}
