import { render, staticRenderFns } from "./comp_ProductCard-Sq.vue?vue&type=template&id=20766f6d&scoped=true&"
import script from "./comp_ProductCard-Sq.vue?vue&type=script&lang=js&"
export * from "./comp_ProductCard-Sq.vue?vue&type=script&lang=js&"
import style0 from "./comp_ProductCard-Sq.vue?vue&type=style&index=0&id=20766f6d&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20766f6d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VHover } from 'vuetify/lib/components/VHover';
installComponents(component, {VCard,VHover})
